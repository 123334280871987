<template>
  <v-dialog v-model="modelValue" max-width="500" @click:outside="closeDialog">
    <v-card>
      <v-card-title class="text-h6 font-weight-bold">
        {{ t("truckingCompany.create_trucking_company") }}
      </v-card-title>

      <v-card-text>
        <p class="mb-4">
          {{ t("truckingCompany.create_trucking_company_text") }}
        </p>

        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="companyName"
              :label="`${t('truckingCompany.trucking_company_name')}*`"
              variant="outlined"
              hide-details="auto"
              aria-required="true"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="companyEmail"
              :label="t('truckingCompany.company_email_address')"
              variant="outlined"
              hide-details="auto"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="companyTaxId"
              :label="`${t('truckingCompany.tax_id')}*`"
              variant="outlined"
              hide-details="auto"
              aria-required="true"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="companyStreetNo"
              :label="t('truckingCompany.street_no')"
              variant="outlined"
              hide-details="auto"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="companyCity"
              :label="t('city')"
              variant="outlined"
              hide-details="auto"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="companyZipCode"
              :label="t('zip_code')"
              variant="outlined"
              hide-details="auto"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="companyCountry"
              :label="t('country')"
              variant="outlined"
              hide-details="auto"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn variant="text" color="primary" @click="closeDialog">
          {{ t("cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          variant="flat"
          rounded="lg"
          class="pa-2"
          @click="confirm"
          :disabled="!isValid"
        >
          {{ t("truckingCompany.create_trucking_company") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import {
  ref,
  computed,
  defineProps,
  defineEmits,
  toRefs,
  watch,
  onBeforeUnmount,
} from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps({
  modelValue: Boolean,
});

const { t } = useI18n();
const { modelValue } = toRefs(props);
const emit = defineEmits(["update:modelValue", "confirm"]);

const companyName = ref("");
const companyEmail = ref("");
const companyTaxId = ref("");
const companyStreetNo = ref("");
const companyCity = ref("");
const companyZipCode = ref("");
const companyCountry = ref("");

const isValid = computed(
  () =>
    companyName.value && companyTaxId.value && companyEmail.value.includes("@"),
);

const resetData = () => {
  companyName.value = "";
  companyEmail.value = "";
  companyTaxId.value = "";
  companyStreetNo.value = "";
  companyCity.value = "";
  companyZipCode.value = "";
  companyCountry.value = "";
};

const closeDialog = () => {
  resetData();
  emit("update:modelValue", false);
};

const confirm = () => {
  emit("confirm", {
    companyName: companyName.value,
    companyEmail: companyEmail.value,
    companyTaxId: companyTaxId.value,
    companyStreetNo: companyStreetNo.value,
    companyCity: companyCity.value,
    companyZipCode: companyZipCode.value,
    companyCountry: companyCountry.value,
  });
  emit("update:modelValue", false);
  resetData();
};

const handleKeyDown = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    closeDialog();
  }
};

watch(modelValue, newValue => {
  if (newValue) {
    document.addEventListener("keydown", handleKeyDown);
  } else {
    document.removeEventListener("keydown", handleKeyDown);
  }
});

onBeforeUnmount(() => {
  document.removeEventListener("keydown", handleKeyDown);
});
</script>
