<template>
  <div>
    <v-row class="pt-4">
      <v-col cols="5">
        <v-text-field
          v-model="search"
          :label="searchText"
          :placeholder="searchPlaceholder ?? searchText"
          prepend-inner-icon="mdi-magnify"
          variant="outlined"
          hide-details
          @update:model-value="emitSearch($event)"
        />
      </v-col>
      <v-col cols="2" align-self="center" class="text-start">
        <v-btn
          variant="tonal"
          append-icon="mdi-filter"
          :elevation="0"
          @click="handleFilterClick"
        >
          {{ $t("filter") }}
        </v-btn>
      </v-col>
      <v-col class="ml-auto" cols="5" align-self="center">
        <slot name="custom-btn" />
      </v-col>
    </v-row>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useDebounce } from "@/composables/useDebounce";

defineProps({
  searchable: {
    type: Boolean,
    default: false,
  },
  filterable: {
    type: Boolean,
    default: false,
  },
  searchType: {
    type: String,
    default: "",
  },
  searchText: {
    type: String,
    default: "",
  },
  searchPlaceholder: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["onSearch", "onFilter"]);

const search = ref("");
const { debounce } = useDebounce(300);

function emitSearch(value: string) {
  search.value = value;
  debounce(() => {
    emit("onSearch", value);
  });
}

function handleFilterClick() {
  emit("onFilter");
}
</script>
