<template>
  <v-dialog v-model="modelValue" max-width="500" @click:outside="closeDialog">
    <v-card>
      <v-card-title class="text-h6 font-weight-bold">
        {{ t("userManagement.create_webapp_user") }}
      </v-card-title>

      <v-card-text>
        <p class="mb-4">
          {{ t("userManagement.create_webapp_user_text") }}
        </p>

        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="firstName"
              :label="t('first_name')"
              variant="outlined"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="lastName"
              :label="t('last_name')"
              variant="outlined"
            />
          </v-col>
        </v-row>

        <v-text-field
          v-model="email"
          :label="t('email_address')"
          variant="outlined"
        />
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn variant="text" color="primary" @click="closeDialog">
          {{ t("cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          variant="flat"
          rounded="lg"
          class="pa-2"
          @click="confirm"
          :disabled="!isValid"
        >
          {{ t("confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import {
  ref,
  computed,
  defineProps,
  defineEmits,
  toRefs,
  watch,
  onBeforeUnmount,
} from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps({
  modelValue: Boolean,
});

const { t } = useI18n();
const { modelValue } = toRefs(props);

const emit = defineEmits(["update:modelValue", "confirm"]);

const firstName = ref("");
const lastName = ref("");
const email = ref("");

const isValid = computed(
  () => firstName.value && lastName.value && email.value.includes("@"),
);

const resetData = () => {
  firstName.value = "";
  lastName.value = "";
  email.value = "";
};

const closeDialog = () => {
  resetData();
  emit("update:modelValue", false);
};

const confirm = () => {
  emit("confirm", {
    firstName: firstName.value,
    lastName: lastName.value,
    email: email.value,
  });
  emit("update:modelValue", false);
  resetData();
};

const handleKeyDown = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    closeDialog();
  }
};

watch(modelValue, newValue => {
  if (newValue) {
    document.addEventListener("keydown", handleKeyDown);
  } else {
    document.removeEventListener("keydown", handleKeyDown);
  }
});

onBeforeUnmount(() => {
  document.removeEventListener("keydown", handleKeyDown);
});
</script>
