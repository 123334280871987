import { WebappUser } from "@/services/client/generated";

export default interface UserAuth {
  userId: string;
  emailVerified: boolean;
  accessToken: string | undefined;
  userData?: WebappUser;
}

// temporary interface
export interface WebappUserTest {
  id: string;
  emailVerified: boolean;
  firstName: string;
  lastName: string;
  email: string;
  createdAt: string;
  role: Role;
  truckingCompany: Model[];
  facilityCompany: Model[];
  facilities: Model[];
  trainOperator: Model[];
}

export interface Role {
  truckingCompany: UserRole;
  facilityOperator: UserRole;
  trainOperator: UserRole;
}

export enum UserRole {
  Admin = "Admin",
  Read = "Read",
  None = "None",
}

export interface Model {
  name: string;
  id: string;
}

export interface WebappUserCreation {
  firstName: string;
  lastName: string;
  email: string;
}
