enum PAGE_NAMES {
  TERMINAL_OPERATOR = "/terminalOperator",
  TRAIN_OPERATOR = "/trainOperator",
  FORWARDER_OPERATOR = "/forwarderOperator",
  USER_MANAGEMENT = "/userManagement",
  LICENSE_MANAGEMENT = "/forwarderOperator/licenseManagement",
  ADMIN = "/admin",
}

export default PAGE_NAMES;
