import { Ref, ref } from "vue";

export function useDialog<T = unknown>({
  onClose,
  onCancel,
  onOpen,
  onSubmit,
}: UseDialogParams<T> = {}): UseDialogReturn<T> {
  const isOpen = ref<boolean>(false);
  const param = ref<T>();

  function open(_param?: T) {
    param.value = _param;
    isOpen.value = true;
    onOpen?.(param.value);
  }

  function submit() {
    isOpen.value = false;
    onSubmit?.(param.value);
    param.value = undefined;
  }

  function cancel() {
    isOpen.value = false;
    onCancel?.(param.value);
    param.value = undefined;
  }

  function close() {
    isOpen.value = false;
    onClose?.(param.value);
    param.value = undefined;
  }

  return {
    isOpen,
    param,
    open,
    submit,
    cancel,
    close,
  };
}

export interface UseDialogParams<T = unknown> {
  onClose?: (param?: T) => void;
  onOpen?: (param?: T) => void;
  onSubmit?: (param?: T) => void;
  onCancel?: (param?: T) => void;
}

export interface UseDialogReturn<T = unknown> extends UseDialogParams<T> {
  param: Ref<T | undefined>;
  open: (param?: T | undefined) => void;
  isOpen: Ref<boolean>;
  submit: () => void;
  cancel: () => void;
  close: () => void;
}
