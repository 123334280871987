<template>
  <TheDialog
    :dialog="dialog"
    :title="t('how_many_licenses')"
    :submit-text="t('purchase')"
    :cancel-text="t('cancel')"
  >
    <template #text>
      <TheTextField type="number" :label="t('quantity')" />
    </template>
  </TheDialog>
</template>

<script setup lang="ts">
import TheDialog from "@/components/dialogs/TheDialog.vue";
import { UseDialogReturn } from "@/composables/useDialog";
import { useI18n } from "vue-i18n";
import TheTextField from "@/components/TheTextField.vue";

interface Props {
  dialog: UseDialogReturn;
}

defineProps<Props>();

const { t } = useI18n();
</script>
