<template>
  <div>
    <TheMainHeader class="text-left pl-10 pt-10" :text="t('conroo_admin')" />
    <div class="d-flex text-left pl-10 pt-2 align-center">
      <span class="bg-greyish pa-2 rounded-lg">
        <v-icon
          :icon="`custom:${groupedItems[3]?.services?.[2]?.icon}`"
          :height="24"
          :width="24"
        />
      </span>
      <span class="text-h4 font-weight-medium text-primary ml-2">
        {{ t(groupedItems[3]?.services?.[2]?.name ?? "missing") }}
      </span>
    </div>

    <div>
      <v-container>
        <VirtualTable
          :items="filteredTruckingCompanies"
          :headers="headers"
          :searchable="true"
          @load-more="allLoaded"
        >
          <template #custom-search>
            <v-row justify="space-between">
              <v-col cols="4" align-self="center">
                <v-text-field
                  v-model="searchQuery"
                  variant="outlined"
                  :label="t('search')"
                  :placeholder="t('truckingCompany.search_by_name_or_tax_id')"
                  clearable
                />
              </v-col>
              <v-col align-self="center">
                <div class="d-flex justify-end">
                  <v-btn
                    style="height: 3em"
                    color="primary"
                    append-icon="mdi-plus"
                    class="px-4"
                    rounded="lg"
                    @click="dialog = true"
                  >
                    {{ t("truckingCompany.create_trucking_company") }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </template>
          <template #item.createdAt="{ value }">
            {{ formatDate(value.createdAt) }}
          </template>
          <template #item.cityZipCode="{ value }">
            {{ value.zipCode }} {{ value.city }}
          </template>
          <template #item.tmsSystem="{ value }">
            {{ value.tmsSystem.length ? value.tmsSystem : "-" }}
          </template>
          <template #item.actions="{ value }">
            <v-btn
              @click="navigateTo(value.id)"
              :icon="'mdi-pencil'"
              variant="text"
              color="primary"
            />
          </template>
        </VirtualTable>
      </v-container>
    </div>

    <CreateTruckerCompanyDialog v-model="dialog" @confirm="handleConfirm" />
  </div>
</template>

<script setup lang="ts">
import TheMainHeader from "@/components/TheMainHeader.vue";
import VirtualTable from "@/components/virtual-table/VirtualTable.vue";
import CreateTruckerCompanyDialog from "@/components/trucker-company/CreateTruckerCompanyDialog.vue";
import getSidebarItems from "@/constants/sidebar-items";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import {
  TruckingCompanyCreation,
  useTruckingCompaniesLogic,
} from "./trucking-companies-logic";
import { useRouter } from "vue-router";

const { t } = useI18n();
const router = useRouter();
const groupedItems = ref(getSidebarItems());
const { truckingCompanies, fetchTruckingCompanies, addTruckingCompany } =
  useTruckingCompaniesLogic();

const searchQuery = ref("");
const dialog = ref(false);

const headers = computed(
  () =>
    [
      {
        title: t("truckingCompany.company_name"),
        value: "companyName",
        align: "center",
      },
      {
        title: t("truckingCompany.company_email"),
        value: "companyEmail",
        align: "center",
      },
      {
        title: t("truckingCompany.tax_id"),
        value: "taxId",
        align: "center",
      },
      {
        title: t("created_date"),
        value: "createdAt",
        align: "center",
      },
      {
        title: t("street"),
        value: "street",
        align: "center",
      },
      {
        title: t("city_zip_code"),
        value: "cityZipCode",
        align: "center",
      },
      {
        title: t("country"),
        value: "country",
        align: "center",
      },
      {
        title: t("truckingCompany.tms_system"),
        value: "tmsSystem",
        align: "center",
      },
      {
        title: t("actions"),
        value: "actions",
        sortable: false,
        align: "center",
      },
    ] as const,
);
const allLoaded = async (
  done: (status: "loading" | "error" | "empty" | "ok") => void,
) => {
  try {
    if (truckingCompanies.value) {
      done("empty");
    } else {
      done("ok");
    }
  } catch (_) {
    done("error");
  }
};
const filteredTruckingCompanies = computed(() => {
  if (!searchQuery.value) {
    return truckingCompanies.value;
  }
  return truckingCompanies.value.filter(company =>
    [company.companyName, company.taxId].some(field =>
      field.toLowerCase().includes(searchQuery.value.toLowerCase()),
    ),
  );
});

const formatDate = (date: Date) => {
  const newDate = new Date(date);
  const day = String(newDate.getDate()).padStart(2, "0");
  const month = String(newDate.getMonth() + 1).padStart(2, "0");
  const year = newDate.getFullYear();
  const formattedDate = `${day}.${month}.${year}`;
  return formattedDate;
};

const handleConfirm = async (payload: TruckingCompanyCreation) => {
  await addTruckingCompany(payload);
};

const navigateTo = (companyId: string) => {
  router.push({ name: "truckingCompanyId", params: { id: companyId } });
};

onMounted(async () => {
  await fetchTruckingCompanies();
});
</script>
