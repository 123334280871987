<template>
  <v-row>
    <v-col cols="4" md="5">
      <div class="text-left">
        <h3>{{ t("userManagement.linkedTruckingCompanies.title") }}</h3>
        <p>
          {{ t("userManagement.linkedTruckingCompanies.description") }}
        </p>
      </div>
    </v-col>

    <v-col cols="8" md="7">
      <v-card class="pa-5">
        <div class="d-flex font-weight-bold pb-3">
          {{
            t(
              "userManagement.linkedTruckingCompanies.add_remove_trucking_company",
            )
          }}
        </div>
        <div class="d-flex align-center justify-center py-2">
          <v-row>
            <template v-if="user.truckingCompany.length">
              <v-col
                v-for="company in user.truckingCompany"
                :key="company.id"
                cols="12"
              >
                <v-text-field
                  v-model="company.name"
                  class="cursor-default"
                  readonly
                  variant="outlined"
                  hide-details="auto"
                  append-inner-icon="mdi-minus-box-outline"
                  @click:appendInner="removeCompany(company.id)"
                />
              </v-col>
            </template>
            <v-col cols="12">
              <v-autocomplete
                ref="acCompanyRef"
                v-model="selectedTruckingCompany"
                :label="
                  t(
                    'userManagement.linkedTruckingCompanies.search_trucking_company',
                  )
                "
                variant="outlined"
                :items="filteredCompanies"
                item-title="name"
                item-value="id"
                prepend-inner-icon="mdi-magnify"
                hide-details="auto"
                :no-data-text="t('no_data_available')"
                @update:model-value="addCompany"
              />
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts" setup>
import { ref, computed, defineProps, toRefs } from "vue";
import { WebappUserTest } from "@/store/interfaces/user-auth";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  user: WebappUserTest;
  allTruckingCompanies: { id: string; name: string }[];
}>();

const { t } = useI18n();
const { user } = toRefs(props);

const selectedTruckingCompany = ref<string>();
const acCompanyRef = ref<HTMLElement | null>(null);
const blurEvent: Event = new Event("blur");

const filteredCompanies = computed(() =>
  props.allTruckingCompanies.filter(
    company => !user.value.truckingCompany.some(t => t.id === company.id),
  ),
);

const addCompany = (id: string) => {
  if (!id) return;

  const companyToAdd = props.allTruckingCompanies.find(
    company => company.id === id,
  );

  if (companyToAdd) {
    user.value.truckingCompany.push(companyToAdd);
  }

  selectedTruckingCompany.value = undefined;
  acCompanyRef.value?.dispatchEvent(blurEvent);
};

const removeCompany = (id: string) => {
  user.value.truckingCompany = props.user.truckingCompany.filter(
    company => company.id !== id,
  );
};
</script>
