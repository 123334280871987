<template>
  <v-row>
    <v-col cols="4" md="5">
      <div class="text-left">
        <h3>{{ t("userManagement.linkedFacilities.title") }}</h3>
        <p>{{ t("userManagement.linkedFacilities.description") }}</p>
      </div>
    </v-col>

    <v-col cols="8" md="7">
      <v-card class="pa-5">
        <div class="d-flex font-weight-bold pb-3">
          {{ t("userManagement.linkedFacilities.add_remove_facility_company") }}
        </div>
        <div class="d-flex align-center justify-center py-2">
          <v-row>
            <template v-if="user.facilityCompany.length">
              <v-col
                v-for="company in user.facilityCompany"
                :key="company.id"
                cols="12"
              >
                <v-text-field
                  v-model="company.name"
                  class="cursor-default"
                  readonly
                  variant="outlined"
                  hide-details="auto"
                  append-inner-icon="mdi-minus-box-outline"
                  @click:appendInner="removeFacilityCompany(company.id)"
                />
              </v-col>
            </template>
            <v-col cols="12">
              <v-autocomplete
                ref="acFacilityCompanyRef"
                v-model="selectedFacilityCompany"
                :label="
                  t('userManagement.linkedFacilities.search_facility_company')
                "
                variant="outlined"
                :items="filteredFacilityCompanies"
                item-title="name"
                item-value="id"
                prepend-inner-icon="mdi-magnify"
                hide-details="auto"
                :no-data-text="t('no_data_available')"
                @update:model-value="addFacilityCompany"
              />
            </v-col>
          </v-row>
        </div>

        <div class="d-flex font-weight-bold py-3">
          {{ t("userManagement.linkedFacilities.add_remove_facility") }}
        </div>
        <div class="d-flex align-center justify-center py-2">
          <v-row>
            <template v-if="user.facilities.length">
              <v-col
                v-for="facility in user.facilities"
                :key="facility.id"
                cols="12"
              >
                <v-text-field
                  v-model="facility.name"
                  class="cursor-default"
                  readonly
                  variant="outlined"
                  hide-details="auto"
                  append-inner-icon="mdi-minus-box-outline"
                  @click:appendInner="removeFacility(facility.id)"
                />
              </v-col>
            </template>
            <v-col cols="12">
              <v-autocomplete
                ref="acFacilityRef"
                v-model="selectedFacility"
                :label="t('userManagement.linkedFacilities.search_facility')"
                variant="outlined"
                :items="filteredFacilities"
                item-title="name"
                item-value="id"
                prepend-inner-icon="mdi-magnify"
                hide-details="auto"
                :no-data-text="t('no_data_available')"
                @update:model-value="addFacility"
              />
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts" setup>
import { ref, computed, defineProps, toRefs } from "vue";
import { WebappUserTest } from "@/store/interfaces/user-auth";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  user: WebappUserTest;
  allFacilityCompanies: { id: string; name: string }[];
  allFacilities: { id: string; name: string }[];
}>();

const { t } = useI18n();
const { user } = toRefs(props);

const selectedFacilityCompany = ref<string>();
const selectedFacility = ref<string>();

const acFacilityCompanyRef = ref<HTMLElement | null>(null);
const acFacilityRef = ref<HTMLElement | null>(null);

const blurEvent: Event = new Event("blur");

const filteredFacilityCompanies = computed(() =>
  props.allFacilityCompanies.filter(
    company => !props.user.facilityCompany.some(fc => fc.id === company.id),
  ),
);

const filteredFacilities = computed(() =>
  props.allFacilities.filter(
    facility => !props.user.facilities.some(f => f.id === facility.id),
  ),
);

const addFacilityCompany = (id: string) => {
  if (!id) return;

  const companyToAdd = props.allFacilityCompanies.find(
    company => company.id === id,
  );
  if (companyToAdd) {
    user.value.facilityCompany.push(companyToAdd);
  }

  selectedFacilityCompany.value = undefined;
  acFacilityCompanyRef.value?.dispatchEvent(blurEvent);
};

const removeFacilityCompany = (id: string) => {
  user.value.facilityCompany = user.value.facilityCompany.filter(
    company => company.id !== id,
  );
};

const addFacility = (id: string) => {
  if (!id) return;

  const facilityToAdd = props.allFacilities.find(
    facility => facility.id === id,
  );
  if (facilityToAdd) {
    user.value.facilities.push(facilityToAdd);
  }

  selectedFacility.value = undefined;
  acFacilityRef.value?.dispatchEvent(blurEvent);
};

const removeFacility = (id: string) => {
  user.value.facilities = user.value.facilities.filter(
    facility => facility.id !== id,
  );
};
</script>
