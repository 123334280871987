<template>
  <div v-if="user">
    <TheMainHeader
      class="text-left pl-10 pt-10"
      :text="`${user.firstName} ${user.lastName}`"
    />

    <div>
      <div class="d-flex justify-space-between px-10 text-h4 text-primary py-2">
        <span>
          {{ t("userManagement.edit_webapp_user") }}
        </span>
        <span>
          <v-btn class="mr-4" variant="text" append-icon="mdi-delete-outline">
            {{ t("discard_changes") }}
          </v-btn>
          <v-btn append-icon="mdi-content-save-outline" variant="tonal">
            {{ t("save_changes") }}
          </v-btn>
        </span>
      </div>
    </div>

    <div class="container py-16">
      <section class="mb-4">
        <GeneralInformationSection :user="user" />
      </section>

      <section class="mb-4">
        <RolesSection :roles="user.role" />
      </section>

      <section class="mb-4">
        <LinkedTruckingCompaniesSection
          :user="user"
          :all-trucking-companies="allTruckingCompanies"
        />
      </section>

      <section class="mb-4">
        <LinkedFacilitiesSection
          :user="user"
          :all-facility-companies="allFacilityCompanies"
          :all-facilities="allFacilities"
        />
      </section>

      <section class="mb-4">
        <LinkedTrainOperatorsSection
          :user="user"
          :all-train-operators="allTrainOperators"
        />
      </section>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import TheMainHeader from "@/components/TheMainHeader.vue";
import { useRoute, useRouter } from "vue-router";
import { useWebappUserLogic } from "./webapp-user-logic";
import { WebappUserTest } from "@/store/interfaces/user-auth";
import GeneralInformationSection from "./sections/GeneralInformationSection.vue";
import RolesSection from "./sections/RolesSection.vue";
import LinkedTruckingCompaniesSection from "./sections/LinkedTruckingCompaniesSection.vue";
import LinkedFacilitiesSection from "./sections/LinkedFacilitiesSection.vue";
import LinkedTrainOperatorsSection from "./sections/LinkedTrainOperatorsSection.vue";
import { useI18n } from "vue-i18n";

const route = useRoute();
const router = useRouter();
const {
  webappUsers,
  allTruckingCompanies,
  allFacilityCompanies,
  allFacilities,
  allTrainOperators,
} = useWebappUserLogic();
const { t } = useI18n();

const user = ref<WebappUserTest>();

onMounted(() => {
  user.value = webappUsers.value.find(u => u.id.toString() === route.params.id);
  if (!user.value) {
    router.push("/admin/webappUsers");
  }
});
</script>

<style scoped lang="css">
.container {
  max-width: 70vw;
  margin: 0 auto;
}
</style>
