import SnackbarType from "@/store/interfaces/snackbar-type";
import {
  UserRole,
  WebappUserCreation,
  WebappUserTest,
} from "@/store/interfaces/user-auth";
import { useSnackbarStore } from "@/store/useSnackbarStore";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

export function useWebappUserLogic() {
  const snackBarStore = useSnackbarStore();
  const { t } = useI18n();

  // temporary mocked data
  const allTruckingCompanies: { id: string; name: string }[] = [
    { id: "1", name: "Tobias Trucking CO." },
    { id: "2", name: "CONROO Trucking" },
  ];

  const allFacilityCompanies: { id: string; name: string }[] = [
    { id: "1", name: "DUSS" },
    { id: "2", name: "Ulm" },
  ];

  const allFacilities: { id: string; name: string }[] = [
    { id: "1", name: "DUSS Terminal Ulm" },
    { id: "2", name: "DUSS Terminal Augsburg" },
    { id: "3", name: "DUSS Terminal Kornwestheim" },
    { id: "4", name: "Ulm" },
  ];

  const allTrainOperators: { id: string; name: string }[] = [
    { id: "1", name: "DB-Cargo" },
    { id: "2", name: "SBB Cargo" },
    { id: "3", name: "EuroRail" },
    { id: "4", name: "Global Freight Train" },
  ];

  const webappUsers = ref<WebappUserTest[]>([
    {
      id: "1",
      firstName: "Tobias",
      lastName: "Häusl",
      email: "tobias.haeusl@conroo.com",
      emailVerified: true,
      createdAt: "2025-01-30T08:30:00Z",
      truckingCompany: [
        {
          name: "Tobias Trucking CO.",
          id: "1",
        },
        {
          name: "CONROO Trucking",
          id: "2",
        },
      ],
      facilityCompany: [
        {
          name: "DUSS",
          id: "1",
        },
      ],
      facilities: [
        {
          name: "DUSS Terminal Ulm",
          id: "1",
        },
        {
          name: "DUSS Terminal Augsburg",
          id: "2",
        },
        {
          name: "DUSS Terminal Kornwestheim",
          id: "3",
        },
      ],
      role: {
        truckingCompany: UserRole.Admin,
        facilityOperator: UserRole.None,
        trainOperator: UserRole.Read,
      },
      trainOperator: [
        {
          name: "DB-Cargo",
          id: "1",
        },
      ],
    },
    {
      id: "2",
      firstName: "Maximilian",
      lastName: "Maierhofer",
      email: "maximilian.maierhofer@conroo.com",
      emailVerified: false,
      role: {
        truckingCompany: UserRole.Admin,
        facilityOperator: UserRole.None,
        trainOperator: UserRole.Read,
      },
      createdAt: "2025-01-30T10:00:00Z",
      truckingCompany: [
        {
          name: "Tobias Trucking CO.",
          id: "1",
        },
      ],
      facilityCompany: [
        {
          name: "DUSS",
          id: "1",
        },
      ],
      facilities: [
        {
          name: "DUSS Terminal Ulm",
          id: "1",
        },
        {
          name: "DUSS Terminal Augsburg",
          id: "2",
        },
        {
          name: "DUSS Terminal Kornwestheim",
          id: "3",
        },
      ],
      trainOperator: [
        {
          name: "DB-Cargo",
          id: "1",
        },
      ],
    },
    {
      id: "3",
      firstName: "Fabian",
      lastName: "Neuner",
      email: "fabian.neuner@conroo.com",
      emailVerified: true,
      role: {
        truckingCompany: UserRole.Admin,
        facilityOperator: UserRole.None,
        trainOperator: UserRole.Read,
      },
      createdAt: "2025-01-29T12:15:00Z",
      truckingCompany: [
        {
          name: "Tobias Trucking CO.",
          id: "1",
        },
      ],
      facilityCompany: [
        {
          name: "DUSS",
          id: "1",
        },
      ],
      facilities: [
        {
          name: "DUSS Terminal Ulm",
          id: "1",
        },
        {
          name: "DUSS Terminal Augsburg",
          id: "2",
        },
        {
          name: "DUSS Terminal Kornwestheim",
          id: "3",
        },
      ],
      trainOperator: [
        {
          name: "DB-Cargo",
          id: "1",
        },
      ],
    },
  ]);

  async function fetchWebappUsers(): Promise<WebappUserTest[]> {
    return webappUsers.value.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );
  }

  async function addWebappUser(newUser: WebappUserCreation) {
    try {
      webappUsers.value.push({
        id: Math.random().toString(36).substr(2, 9),
        firstName: newUser.firstName,
        lastName: newUser.lastName,
        email: newUser.email,
        emailVerified: false,
        role: {
          truckingCompany: UserRole.None,
          facilityOperator: UserRole.None,
          trainOperator: UserRole.None,
        },
        createdAt: new Date().toISOString(),
        truckingCompany: [],
        trainOperator: [],
        facilityCompany: [],
        facilities: [],
      });
      snackBarStore.showSnackbar({
        text: t("userManagement.webapp_created_successfully"),
        snackbarType: SnackbarType.SUCCESS,
      });
    } catch (e) {
      snackBarStore.showSnackbar({
        text: t(`Error: ${e}`),
        snackbarType: SnackbarType.ERROR,
      });
    }
  }

  return {
    webappUsers,
    allFacilityCompanies,
    allFacilities,
    allTruckingCompanies,
    allTrainOperators,
    fetchWebappUsers,
    addWebappUser,
  };
}
