import { defineStore } from "pinia";
import {
  License,
  LicenseLoadingFlags,
  LicenseManagedBy,
  LicenseStatus,
} from "@/models/license-management";
import { ref, reactive } from "vue";
import { forwarderTruckersClient } from "@/services/client/configs/services";

export const useLicenseManagementStore = defineStore(
  "licenseManagementStore",
  () => {
    const licenses = ref<License[] | null>(null);
    const loadingFlags = reactive<LicenseLoadingFlags>({
      fetchLicenses: false,
    });

    async function fetchLicenses(payload: {
      userId: string;
      forwarderId: number;
      searchTerm?: string;
      status?: LicenseStatus[];
      managedBy?: LicenseManagedBy[];
      page?: number;
      expiresBefore?: string;
    }) {
      try {
        if (payload.page === 0) licenses.value = null;
        loadingFlags.fetchLicenses = true;
        const response = await forwarderTruckersClient.getLicensesForForwarder(
          payload.userId,
          payload.forwarderId,
          {
            search: payload.searchTerm,
            expirationDate: payload.expiresBefore,
            licenseStatuses: payload.status,
            managedBy: payload.managedBy,
          },
          payload.page,
          50,
        );
        const result = response.data;
        if (payload.page && payload.page > 0) {
          licenses.value = [...(licenses.value || []), ...result];
        } else {
          licenses.value = result;
        }
        return result;
      } finally {
        loadingFlags.fetchLicenses = false;
      }
    }

    return {
      licenses,
      loadingFlags,
      fetchLicenses,
    };
  },
);
