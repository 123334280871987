<template>
  <div class="pa-10">
    <TheMainHeader class="text-left pt-10" :text="$t('licenseManagement')" />
    <v-card class="pa-4 mt-10">
      <LicenseManagementTable
        :page="page"
        :licenses="licenses ?? []"
        @on-search="handleSearch"
        @on-filter="handleFilterClick"
        @onLoadMore="handleLoadMore"
        @on-claim-license="handleClaimLicenseClick"
        @on-purchase-license="handlePurchaseClick"
        @on-license-assign="handleAssignClick"
      />
      <LicenseManagementFiltersDialog
        :dialog="filterDialog"
        v-model="filter"
        @on-apply="handleFilterApplyClick"
      />
      <LicenseManagementClaimLicenseDialog :dialog="claimLicenseDialog" />
      <LicenseManagementPurchaseDialog :dialog="purchaseDialog" />
      <LicenseManagementAssignDialog
        :dialog="assignDialog"
        :truckers="truckersForForwarder"
        :truckers-loading="truckersLoading"
        @on-search="handleSearchTruckers"
        @on-load-more-truckers="handleLoadMoreTruckers"
      />
    </v-card>
  </div>
</template>

<script setup lang="ts">
import TheMainHeader from "@/components/TheMainHeader.vue";
import LicenseManagementTable from "@/components/license-management/LicenseManagementTable.vue";
import { useLicenseManagementLogic } from "@/views/forwarder-operator/license-management/license-management-logic";
import LicenseManagementPurchaseDialog from "@/components/license-management/LicenseManagementPurchaseDialog.vue";
import LicenseManagementAssignDialog from "@/components/license-management/LicenseManagementAssignDialog.vue";
import LicenseManagementFiltersDialog from "@/components/license-management/LicenseManagementFiltersDialog.vue";
import LicenseManagementClaimLicenseDialog from "@/components/license-management/LicenseManagementClaimLicenseDialog.vue";

const {
  page,
  licenses,
  truckersForForwarder,
  filterDialog,
  assignDialog,
  claimLicenseDialog,
  purchaseDialog,
  filter,
  truckersLoading,
  handleFilterApplyClick,
  handleFilterClick,
  handleClaimLicenseClick,
  handlePurchaseClick,
  handleAssignClick,
  handleSearch,
  handleSearchTruckers,
  handleLoadMoreTruckers,
  handleLoadMore,
} = useLicenseManagementLogic();
</script>
