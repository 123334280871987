<template>
  <v-dialog
    :model-value="dialog.isOpen.value"
    v-bind="optionals"
    :min-width="minWidth"
    :max-width="maxWidth"
    @update:model-value="dialog.close()"
  >
    <slot>
      <v-card>
        <v-card-title class="px-6 py-4">
          <slot name="title">
            {{ title }}
          </slot>
        </v-card-title>
        <v-card-text v-if="$slots.text" class="px-6 pb-0 pt-0">
          <slot name="text">
            {{ text }}
          </slot>
        </v-card-text>
        <v-card-actions class="px-6 py-4">
          <slot name="actions">
            <v-spacer></v-spacer>
            <slot name="cancelBtn">
              <TheButton
                v-if="cancelText"
                class="text-none"
                :block="false"
                size="default"
                variant="plain"
                :elevation="0"
                :title="cancelText"
                @click="dialog.cancel"
              />
            </slot>

            <slot name="submitBtn">
              <TheButton
                v-if="submitText"
                class="text-none"
                :block="false"
                size="default"
                :title="submitText"
                @click="handleSubmit"
              />
            </slot>
          </slot>
        </v-card-actions>
      </v-card>
    </slot>
  </v-dialog>
</template>

<script setup lang="ts" generic="T">
import { computed } from "vue";
import { UseDialogReturn } from "@/composables/useDialog";
import TheButton from "@/components/TheButton.vue";

interface Props {
  dialog: UseDialogReturn<T>;
  autoWidth?: boolean;
  title?: string;
  text?: string;
  cancelText?: string;
  submitText?: string;
  minWidth?: string;
  maxWidth?: string;
}

const props = defineProps<Props>();
const emit = defineEmits(["onSubmit"]);

const optionals = computed(() => ({
  ...(props.autoWidth && { width: "auto" }),
}));

function handleSubmit() {
  props.dialog.submit();
  emit("onSubmit");
}
</script>
