<template>
  <v-row>
    <v-col cols="4" md="5">
      <div class="text-left">
        <h3>{{ t("userManagement.generalInformation.title") }}</h3>
        <p>{{ t("userManagement.generalInformation.description") }}</p>
      </div>
    </v-col>

    <v-col cols="8" md="7">
      <v-card class="pa-5">
        <div class="d-flex align-center justify-center py-2">
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="user.firstName"
                :label="t('first_name')"
                hide-details="auto"
                variant="outlined"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="user.lastName"
                hide-details="auto"
                :label="t('last_name')"
                variant="outlined"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="user.email"
                hide-details="auto"
                :label="t('email_address')"
                variant="outlined"
                disabled
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                :model-value="formatDate(user.createdAt)"
                :label="t('created_date')"
                hide-details="auto"
                variant="outlined"
                disabled
              />
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { toRefs } from "vue";
import { WebappUserTest } from "@/store/interfaces/user-auth";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  user: WebappUserTest;
}>();

const { t } = useI18n();
const { user } = toRefs(props);

const formatDate = (date: string) => new Date(date).toLocaleString();
</script>
