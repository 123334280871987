<template>
  <v-row>
    <v-col cols="4" md="5">
      <div class="text-left">
        <h3>{{ t("userManagement.linkedTrainOperators.title") }}</h3>
        <p>{{ t("userManagement.linkedTrainOperators.description") }}</p>
      </div>
    </v-col>

    <v-col cols="8" md="7">
      <v-card class="pa-5">
        <div class="d-flex font-weight-bold pb-3">
          {{
            t("userManagement.linkedTrainOperators.add_remove_train_operator")
          }}
        </div>
        <div class="d-flex align-center justify-center py-2">
          <v-row>
            <template v-if="user.trainOperator.length">
              <v-col
                v-for="operator in user.trainOperator"
                :key="operator.id"
                cols="12"
              >
                <v-text-field
                  v-model="operator.name"
                  class="cursor-default"
                  readonly
                  variant="outlined"
                  hide-details="auto"
                  append-inner-icon="mdi-minus-box-outline"
                  @click:appendInner="removeTrainOperator(operator.id)"
                />
              </v-col>
            </template>
            <v-col cols="12">
              <v-autocomplete
                ref="acTrainRef"
                v-model="selectedTrain"
                :label="
                  t('userManagement.linkedTrainOperators.search_train_operator')
                "
                variant="outlined"
                :items="filteredTrainOperators"
                item-title="name"
                item-value="id"
                prepend-inner-icon="mdi-magnify"
                hide-details="auto"
                :no-data-text="t('no_data_available')"
                @update:model-value="addTrainOperator"
              />
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts" setup>
import { ref, computed, defineProps, toRefs } from "vue";
import { WebappUserTest } from "@/store/interfaces/user-auth";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  user: WebappUserTest;
  allTrainOperators: { id: string; name: string }[];
}>();

const { t } = useI18n();
const { user } = toRefs(props);

const selectedTrain = ref<string>();
const acTrainRef = ref<HTMLElement | null>(null);
const blurEvent: Event = new Event("blur");

const filteredTrainOperators = computed(() =>
  props.allTrainOperators.filter(
    operator => !user.value.trainOperator.some(to => to.id === operator.id),
  ),
);

const addTrainOperator = (id: string) => {
  if (!id) return;

  const operatorToAdd = props.allTrainOperators.find(
    operator => operator.id === id,
  );
  if (operatorToAdd) {
    user.value.trainOperator.push(operatorToAdd);
  }

  selectedTrain.value = undefined;
  acTrainRef.value?.dispatchEvent(blurEvent);
};

const removeTrainOperator = (id: string) => {
  user.value.trainOperator = user.value.trainOperator.filter(
    operator => operator.id !== id,
  );
};
</script>
