import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import i18n from "./plugins/i18n";
import Vue3Lottie from "vue3-lottie";

import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import "../firebase";
import { useAuthStore } from "./store/useAuthStore";
import { useSnackbarStore } from "./store/useSnackbarStore";
import {
  browserLocalPersistence,
  getAuth,
  onAuthStateChanged,
  setPersistence,
  User,
} from "firebase/auth";
import { terimnalUserClient } from "./services/client/configs/services";
import SnackbarType from "./store/interfaces/snackbar-type";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App);

app.use(pinia);
app.use(router);
app.use(vuetify);
app.use(i18n);
app.use(Vue3Lottie);

app.mount("#app");

const auth = getAuth();

async function initializeAuth() {
  const snackbarStore = useSnackbarStore();

  try {
    await setPersistence(auth, browserLocalPersistence);

    onAuthStateChanged(auth, async (user: User | null) => {
      const authStore = useAuthStore();

      if (user) {
        try {
          authStore.setFirebaseUser(user);

          const token = await user.getIdToken(true);
          authStore.setUser({
            ...authStore.authUser,
            userId: user.uid,
            emailVerified: user.emailVerified,
            accessToken: token,
          });

          if (!authStore.authUser?.userData) {
            const response = await terimnalUserClient.getUserById1(user.uid);
            authStore.setUserData(response.data);
          }
        } catch (_) {
          snackbarStore.showSnackbar({
            text: "Error during user data setup. Please try again.",
            snackbarType: SnackbarType.ERROR,
          });
        }
      } else {
        authStore.logout();
      }
    });
  } catch (_) {
    snackbarStore.showSnackbar({
      text: "Error initializing authentication. Please refresh the page.",
      snackbarType: SnackbarType.ERROR,
    });
  }
}

initializeAuth();
