<template>
  <TheDialog
    :dialog="dialog"
    :title="t('claim_license')"
    :submit-text="t('claim_license')"
    :cancel-text="t('cancel')"
    max-width="600px"
  >
    <template #text>
      {{ t("claim_license_description") }}
      <div class="mt-5">
        <TheTextField
          :label="t('enter_order_id_or_license_key')"
          variant="outlined"
        />
      </div>
    </template>
  </TheDialog>
</template>

<script setup lang="ts">
import TheTextField from "@/components/TheTextField.vue";
import { useI18n } from "vue-i18n";
import TheDialog from "@/components/dialogs/TheDialog.vue";
import { UseDialogReturn } from "@/composables/useDialog";

interface Props {
  dialog: UseDialogReturn;
}

defineProps<Props>();
const { t } = useI18n();
</script>
