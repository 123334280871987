<template>
  <div v-if="company">
    <TheMainHeader class="text-left pl-10 pt-10" :text="company.companyName" />

    <div>
      <div class="d-flex justify-space-between px-10 text-h4 text-primary py-2">
        <span>
          {{ t("truckingCompany.edit_trucking_company") }}
        </span>
        <span>
          <v-btn class="mr-4" variant="text" append-icon="mdi-delete-outline">
            {{ t("discard_changes") }}
          </v-btn>
          <v-btn append-icon="mdi-content-save-outline" variant="tonal">
            {{ t("save_changes") }}
          </v-btn>
        </span>
      </div>
    </div>

    <div class="container py-16">
      <section>
        <GeneralInformationSection :company="company" />
      </section>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import TheMainHeader from "@/components/TheMainHeader.vue";
import { useRoute, useRouter } from "vue-router";
import GeneralInformationSection from "./sections/GeneralInformationSection.vue";
import { useI18n } from "vue-i18n";
import {
  TruckingCompany,
  useTruckingCompaniesLogic,
} from "./trucking-companies-logic";

const route = useRoute();
const router = useRouter();

const { truckingCompanies } = useTruckingCompaniesLogic();

const { t } = useI18n();
const company = ref<TruckingCompany>();

onMounted(() => {
  company.value = truckingCompanies.value.find(
    u => u.id.toString() === route.params.id,
  );
  if (!company.value) {
    router.push("/admin/truckingCompanies");
  }
});
</script>

<style scoped lang="css">
.container {
  max-width: 70vw;
  margin: 0 auto;
}
</style>
