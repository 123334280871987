<template>
  <v-row>
    <v-col cols="4" md="5">
      <div class="text-left">
        <h3>{{ t("userManagement.roles.title") }}</h3>
        <p>
          {{ t("userManagement.roles.description") }}
        </p>
      </div>
    </v-col>

    <v-col cols="8" md="7">
      <v-card class="pa-5">
        <div class="d-flex align-center justify-center py-2">
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="roles.truckingCompany"
                hide-details="auto"
                :items="allRoles"
                :label="t('userManagement.roles.select_trucking_company_role')"
                variant="outlined"
              />
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="roles.facilityOperator"
                :items="allRoles"
                hide-details="auto"
                :label="t('userManagement.roles.select_facility_operator_role')"
                variant="outlined"
              />
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="roles.trainOperator"
                :items="allRoles"
                hide-details="auto"
                :label="t('userManagement.roles.select_train_operator_role')"
                variant="outlined"
              />
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { Role } from "@/store/interfaces/user-auth";
import { toRefs } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  roles: Role;
}>();

const { t } = useI18n();
const { roles } = toRefs(props);
const allRoles = ["Admin", "None", "Write", "Read"];
</script>
