import { useLicenseManagementStore } from "@/store/useLicenseManagementStore";
import { computed, ref, watch } from "vue";
import { License, LicenseFilter } from "@/models/license-management";
import { useDialog } from "@/composables/useDialog";
import { VirtualTableLoadMoreStatus } from "@/models/virtual-table";
import { formatDate } from "@/utils/date-utils";
import { useAuthStore } from "@/store/useAuthStore";
import { useForwarderStore } from "@/store/useForwarderStore";

export function useLicenseManagementLogic() {
  const store = useLicenseManagementStore();
  const filterDialog = useDialog();
  const purchaseDialog = useDialog();
  const assignDialog = useDialog<License>();
  const claimLicenseDialog = useDialog();
  const authStore = useAuthStore();
  const forwarderStore = useForwarderStore();
  const searchTerm = ref<string>("");
  const truckersSearchTerm = ref<string>("");
  const filter = ref<LicenseFilter>({
    status: [],
    managedBy: [],
    expiresBefore: null,
  });
  const page = ref<number>(0);
  const truckersPage = ref<number>(0);
  const infinityScrollControl = ref<
    ((status: VirtualTableLoadMoreStatus) => void) | null
  >(null);
  const truckersInfinityScrollControl = ref<
    ((status: VirtualTableLoadMoreStatus) => void) | null
  >(null);
  const truckersLoading = ref<boolean>(false);

  const licenses = computed(() => store.licenses);
  const truckersForForwarder = computed(
    () => forwarderStore.truckersForForwarder,
  );

  watch(
    () => forwarderStore.getForwarder()?.id,
    async () => {
      await getTruckersForForwarder();
    },
    { immediate: true },
  );

  watch(
    () => forwarderStore.getForwarder()?.id,
    async () => {
      page.value = 0;
      await fetchLicenses(infinityScrollControl.value);
      page.value = page.value + 1;
    },
  );

  async function fetchLicenses(
    tableControl?: ((status: VirtualTableLoadMoreStatus) => void) | null,
  ) {
    tableControl?.("loading");
    try {
      const userId: string = authStore.authUser?.userId ?? "";
      const forwarderId: number = forwarderStore.getForwarder()?.id ?? 0;
      const status = filter.value.status.map(status => status.value);
      const managedBy = filter.value.managedBy.map(
        managedBy => managedBy.value,
      );
      const expiresBefore = filter.value.expiresBefore
        ? formatDate(filter.value.expiresBefore.toISOString(), "yyyy-MM-dd")
        : undefined;
      const result = await store.fetchLicenses({
        userId,
        forwarderId,
        status,
        managedBy,
        page: page.value,
        searchTerm: searchTerm.value,
        expiresBefore,
      });

      if (result.length === 0) {
        tableControl?.("empty");
      } else {
        tableControl?.("ok");
      }
    } catch (err) {
      tableControl?.("error");
      throw err;
    }
  }

  async function getTruckersForForwarder() {
    try {
      truckersInfinityScrollControl.value?.("loading");
      const userId: string = authStore.authUser?.userId ?? "";
      const forwarderId: number = forwarderStore.getForwarder()?.id ?? 0;
      const page = truckersPage.value;
      const result = await forwarderStore.getTruckersForForwarder({
        userId,
        forwarderId,
        page,
        size: 50,
      });
      if (result.length === 0) {
        truckersInfinityScrollControl.value?.("empty");
      } else {
        truckersInfinityScrollControl.value?.("ok");
      }
    } finally {
      truckersLoading.value = false;
    }
  }

  async function handleFilterApplyClick() {
    page.value = 0;
    await fetchLicenses(infinityScrollControl.value);
    page.value = page.value + 1;
  }

  async function handleSearch(value: string) {
    searchTerm.value = value;
    page.value = 0;
    await fetchLicenses(infinityScrollControl.value);
    page.value = page.value + 1;
  }

  async function handleSearchTruckers(value: string) {
    truckersSearchTerm.value = value;
    truckersPage.value = 0;
    await getTruckersForForwarder();
  }

  function handleFilterClick(): void {
    filterDialog.open();
  }

  function handleClaimLicenseClick() {
    claimLicenseDialog.open();
  }

  function handleAssignClick(license: License): void {
    assignDialog.open(license);
  }

  function handlePurchaseClick() {
    purchaseDialog.open();
  }

  async function handleLoadMore(
    done: (status: VirtualTableLoadMoreStatus) => void,
  ) {
    if (!infinityScrollControl.value) {
      infinityScrollControl.value = done;
    }
    await fetchLicenses(done);
    page.value = page.value + 1;
  }

  async function handleLoadMoreTruckers(
    done: (status: VirtualTableLoadMoreStatus) => void,
  ) {
    truckersInfinityScrollControl.value = done;
    truckersPage.value = truckersPage.value + 1;
    getTruckersForForwarder();
  }

  return {
    page,
    licenses,
    truckersForForwarder,
    loadingFlags: store.loadingFlags,
    truckersLoading,
    fetchLicenses: store.fetchLicenses,
    filter,
    filterDialog,
    purchaseDialog,
    assignDialog,
    claimLicenseDialog,
    handleFilterClick,
    handleClaimLicenseClick,
    handlePurchaseClick,
    handleAssignClick,
    handleSearch,
    handleSearchTruckers,
    handleLoadMore,
    handleLoadMoreTruckers,
    handleFilterApplyClick,
  };
}
