<template>
  <v-row dense no-gutters justify="end" align="center">
    <v-col cols="auto">
      <TheButton
        v-if="props.actions?.length"
        class="text-none"
        :block="false"
        size="default"
        variant="plain"
        :elevation="0"
        :title="actionBtns[0].label"
        :disabled="actionBtns[0].isDisabled"
        @click="handleClick(actionBtns[0].action)"
      >
        <slot :name="actionBtns[0].action" />
      </TheButton>
    </v-col>

    <v-col cols="auto">
      <v-menu v-if="(props.actions?.length || 0) > 1">
        <template #activator="{ props: activatorProps }">
          <v-btn
            icon="mdi-dots-vertical"
            variant="text"
            v-bind="activatorProps"
          ></v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="actionBtn in actionBtns.slice(1)"
            :key="actionBtn.action"
          >
            <TheButton
              class="text-none"
              :block="false"
              size="default"
              variant="plain"
              :elevation="0"
              :title="actionBtn.label"
              :disabled="actionBtn.isDisabled"
              @click="handleClick(actionBtn.action)"
            >
              <slot :name="actionBtn.action" />
            </TheButton>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import {
  LicenseManagementTableActions,
  LicenseManagementTableActionsEmitEvent,
} from "@/models/license-management";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import TheButton from "@/components/TheButton.vue";
import { transformLicenseActionToEvent } from "@/utils/license-management/license-management-table-utils";

interface Props {
  actions: LicenseManagementTableActions[];
  disabledActions?: LicenseManagementTableActions[];
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (event: LicenseManagementTableActionsEmitEvent): void;
}>();
const { t } = useI18n();

const actionBtns = computed(() => {
  return props.actions.map((action: LicenseManagementTableActions) => ({
    action: action,
    isDisabled: (props.disabledActions ?? []).includes(action),
    label: labelsMap.value[action],
  }));
});

const labelsMap = computed<Record<LicenseManagementTableActions, string>>(
  () => ({
    ASSIGN: t("assign"),
    STOP_RENEWAL: t("stop_renewal"),
    REVOKE: t("revoke"),
    PAY_SETUP_FEE: t("pay_setup_fee"),
    TAKEOVER_MANAGEMENT: t("takeover_management"),
  }),
);

function handleClick(action: LicenseManagementTableActions) {
  emit(transformLicenseActionToEvent(action));
}
</script>
