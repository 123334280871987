<template>
  <div :class="{ scrollable: scrollable }">
    <v-progress-circular
      v-if="loading"
      color="primary"
      indeterminate
      class="mt-3"
    />

    <v-infinite-scroll
      @load="onScroll"
      :mode="infiniteScrollMode"
      min-height="150"
      :min-width="minWidth"
      style="overflow-y: unset"
    >
      <v-data-table-virtual
        :items="items"
        :headers="headers"
        :item-key="itemKey"
        :no-data-text="noDataText"
        :row-props="rowProps"
        :show-select="showSelect"
        :search="search"
        :custom-filter="customFilter"
        :item-value="itemValue"
        :return-object="returnObject"
        :select-strategy="selectStrategy"
        @update:model-value="onModelValueUpdate"
      >
        <template #header.select>
          <slot name="selectAll" />
        </template>

        <template
          v-for="header in headers"
          #[`item.${header.value}`]="{ item, index }"
        >
          <slot :name="`item.${header.value}`" :value="item" :index="index">
            {{ defaultItem(item, header.value as keyof T) }}
          </slot>
        </template>

        <template #no-data>
          {{ loading ? `${$t("loading")}...` : $t("no_data_available") }}
        </template>
      </v-data-table-virtual>

      <template #empty>
        <v-alert
          v-if="
            items.length &&
            pagesLoaded > 0 &&
            items.length >= lengthNeededForTable
          "
          type="info"
        >
          {{ $t("no_more_items") }}
        </v-alert>
      </template>
    </v-infinite-scroll>
  </div>
</template>

<script setup lang="ts" generic="T">
import { PropType } from "vue";

const props = defineProps({
  pagesLoaded: {
    type: Number,
    default: 0,
    required: true,
  },
  items: {
    type: Array as PropType<readonly T[]>,
    required: true,
  },
  headers: {
    type: Array as PropType<
      readonly {
        key?: string;
        title: string;
        value: string;
        align?: "start" | "end" | "center";
      }[]
    >,
    required: true,
  },
  itemKey: {
    type: String,
    default: "id",
  },
  noDataText: {
    type: String,
    default: "No data available",
  },
  rowProps: {
    type: Function as PropType<
      (row: {
        index: number;
        internalItem: object;
        item: T;
      }) => Record<string, unknown>
    >,
    required: false,
    default: () => ({}),
  },
  showSelect: {
    type: Boolean,
    default: false,
  },
  search: {
    type: String,
    default: "",
  },
  customFilter: {
    type: Function as PropType<
      (
        value: string,
        query: string,
        item?: { value: unknown; raw: T },
      ) => boolean
    >,
    default: () => true,
  },
  itemValue: {
    type: String,
    default: "",
    required: false,
  },
  returnObject: {
    type: Boolean,
    default: false,
  },
  selectStrategy: {
    type: String as PropType<"single" | "page" | "all">,
    default: "page",
    required: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  infiniteScrollMode: {
    type: String as PropType<"manual" | "intersect">,
    default: "intersect",
    required: false,
  },
  scrollable: {
    type: Boolean,
    default: false,
  },
  minWidth: {
    type: Number,
  },
});

const emit = defineEmits(["update:modelValue", "load-more"]);

const onScroll = ({
  done,
}: {
  done: (status: "loading" | "error" | "empty" | "ok") => void;
}) => {
  if (!props.items || props.items.length === 0) {
    done("empty");
  }

  emit("load-more", done);
};

const onModelValueUpdate = (event: unknown) => {
  emit("update:modelValue", event);
};

const defaultItem = (item: T, header: keyof T) => {
  return item[header];
};

const lengthNeededForTable = 49;
</script>

<style lang="scss" scoped>
.scrollable {
  overflow-y: hidden;
  overflow-x: auto;
}
</style>
