<template>
  <v-row>
    <v-col cols="4" md="5">
      <div class="text-left">
        <h3>{{ t("truckingCompany.generalInformation.title") }}</h3>
        <p>{{ t("truckingCompany.generalInformation.description") }}</p>
      </div>
    </v-col>

    <v-col cols="8" md="7">
      <v-card class="pa-5">
        <div class="d-flex align-center justify-center py-2">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="company.companyName"
                :label="`${t('truckingCompany.trucking_company_name')}*`"
                variant="outlined"
                hide-details="auto"
                aria-required="true"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="company.companyEmail"
                :label="t('truckingCompany.company_email_address')"
                variant="outlined"
                hide-details="auto"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="company.taxId"
                :label="`${t('truckingCompany.tax_id')}*`"
                variant="outlined"
                hide-details="auto"
                aria-required="true"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="company.street"
                :label="t('truckingCompany.street_no')"
                variant="outlined"
                hide-details="auto"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="company.city"
                :label="t('city')"
                variant="outlined"
                hide-details="auto"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="company.zipCode"
                :label="t('zip_code')"
                variant="outlined"
                hide-details="auto"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="company.country"
                :label="t('country')"
                variant="outlined"
                hide-details="auto"
              />
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { toRefs } from "vue";
import { useI18n } from "vue-i18n";
import { TruckingCompany } from "../trucking-companies-logic";

const props = defineProps<{
  company: TruckingCompany;
}>();
const { t } = useI18n();
const { company } = toRefs(props);
</script>
