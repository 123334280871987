<template>
  <span :class="vTextClass">
    <slot>
      {{ text }}
    </slot>
  </span>
</template>

<script setup lang="ts">
import { computed } from "vue";

interface Props {
  color?: string;
  text?: string;
}

const props = defineProps<Props>();

// Add support for hex colors if needed;
// This only works for colors specified in Vuetify.ts
const vTextClass = computed(() => {
  return `text-${props.color}`;
});
</script>
