<template>
  <div>
    <TheMainHeader class="text-left pl-10 pt-10" :text="t('conroo_admin')" />
    <div class="d-flex text-left pl-10 pt-2 align-center">
      <span class="bg-greyish pa-2 rounded-lg">
        <v-icon
          :icon="`custom:${groupedItems[3]?.services?.[0]?.icon}`"
          :height="24"
          :width="24"
        />
      </span>
      <span class="text-h4 font-weight-medium text-primary ml-2">
        {{ t(groupedItems[3]?.services?.[1]?.name ?? "missing") }}
      </span>
    </div>

    <div>
      <v-container>
        <VirtualTable
          :items="filteredUsers"
          :headers="headers"
          :searchable="true"
          @load-more="allLoaded"
        >
          <template #custom-search>
            <v-row justify="space-between">
              <v-col cols="4" align-self="center">
                <v-text-field
                  v-model="searchQuery"
                  variant="outlined"
                  :label="t('search')"
                  :placeholder="t('userManagement.search_by_email_or_name')"
                  clearable
                />
              </v-col>
              <v-col cols="3" align-self="center">
                <div class="d-flex justify-end">
                  <v-btn
                    style="height: 3em"
                    color="primary"
                    append-icon="mdi-plus"
                    class="px-4"
                    rounded="lg"
                    @click="dialog = true"
                  >
                    {{ t("userManagement.create_webapp_user") }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </template>
          <template #item.emailVerified="{ value }">
            {{ getEmailVerified(value.emailVerified) }}
          </template>
          <template #item.createdAt="{ value }">
            {{ formatDate(value.createdAt) }}
          </template>
          <template #item.truckingCompany="{ value }">
            {{ getName(value.truckingCompany) }}
          </template>
          <template #item.facility="{ value }">
            {{ getName(value.facilityCompany) }}
          </template>
          <template #item.trainOperator="{ value }">
            {{ getName(value.trainOperator) }}
          </template>
          <template #item.actions="{ value }">
            <v-btn
              @click="navigateToUser(value.id)"
              :icon="'mdi-pencil'"
              variant="text"
              color="primary"
            />
          </template>
        </VirtualTable>
      </v-container>
    </div>

    <CreateUserDialog v-model="dialog" @confirm="handleConfirm" />
  </div>
</template>

<script setup lang="ts">
import TheMainHeader from "@/components/TheMainHeader.vue";
import CreateUserDialog from "@/components/user-management/CreateUserDialog.vue";
import VirtualTable from "@/components/virtual-table/VirtualTable.vue";
import getSidebarItems from "@/constants/sidebar-items";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useWebappUserLogic } from "./webapp-user-logic";
import { useRouter } from "vue-router";
import {
  WebappUserCreation,
  WebappUserTest,
} from "@/store/interfaces/user-auth";

const { fetchWebappUsers, addWebappUser } = useWebappUserLogic();
const { t } = useI18n();
const router = useRouter();

const dialog = ref(false);
const groupedItems = ref(getSidebarItems());

const users = ref<WebappUserTest[]>([]);
const searchQuery = ref("");

const headers = computed(
  () =>
    [
      {
        title: t("first_name"),
        value: "firstName",
        align: "center",
      },
      { title: t("last_name"), value: "lastName", align: "center" },
      {
        title: t("email"),
        value: "email",
        align: "center",
      },
      { title: t("email_status"), value: "emailVerified", align: "center" },
      {
        title: t("created_date"),
        value: "createdAt",
        align: "center",
      },
      {
        title: t("trucking_company"),
        value: "truckingCompany",
        align: "center",
      },
      {
        title: t("facility"),
        value: "facility",
        align: "center",
      },
      {
        title: t("train_operator"),
        value: "trainOperator",
        align: "center",
      },
      {
        title: t("actions"),
        value: "actions",
        sortable: false,
        align: "center",
      },
    ] as const,
);

const allLoaded = async (
  done: (status: "loading" | "error" | "empty" | "ok") => void,
) => {
  try {
    if (users.value) {
      done("empty");
    } else {
      done("ok");
    }
  } catch (_) {
    done("error");
  }
};

const filteredUsers = computed(() => {
  if (!searchQuery.value) {
    return users.value;
  }
  return users.value.filter(user =>
    [user.firstName, user.lastName, user.email].some(field =>
      field.toLowerCase().includes(searchQuery.value.toLowerCase()),
    ),
  );
});

const getEmailVerified = (verified: boolean) => {
  return verified ? t("verified") : t("not_verified");
};

const formatDate = (date: Date) => {
  const newDate = new Date(date);
  const day = String(newDate.getDate()).padStart(2, "0");
  const month = String(newDate.getMonth() + 1).padStart(2, "0");
  const year = newDate.getFullYear();

  const formattedDate = `${day}.${month}.${year}`;

  return formattedDate;
};

const navigateToUser = (userId: string) => {
  router.push({ name: "webappUserId", params: { id: userId } });
};

const handleConfirm = async (payload: WebappUserCreation) => {
  await addWebappUser(payload);
};

const getName = (key: { id: string; name: string }[]) => {
  if (key.length && key[0].name) {
    return key[0].name;
  }
  return "-";
};

onMounted(async () => {
  users.value = await fetchWebappUsers();
});
</script>
