import { addMonths, format, isAfter, isBefore, parseISO } from "date-fns";

export const formatDate = (date: string, dateFormat = "dd.MM.yyyy") => {
  if (!date) return "";
  return format(new Date(date), dateFormat);
};

export function getDateStatus(
  expirationDate: string,
): "active" | "expiring" | "expired" {
  const currentDate = new Date();
  const expiration = parseISO(expirationDate);
  const oneMonthBeforeExpiration = addMonths(expiration, -1);

  if (isAfter(currentDate, expiration)) {
    return "expired";
  } else if (isBefore(currentDate, oneMonthBeforeExpiration)) {
    return "active";
  } else {
    return "expiring";
  }
}
