import { ref, computed, watch, onMounted } from "vue";
import { format, compareAsc } from "date-fns";
import { useAuthStore } from "@/store/useAuthStore";
import { useForwarderStore } from "@/store/useForwarderStore";
import { useTerminalStore } from "@/store/useTerminalStore";
import {
  TerminalOperatorBookingTO,
  TerminalOperatorBookingTOStateEnum,
} from "@/services/client/generated";
import { terminalOperatorTruckerClient } from "@/services/client/configs/services";
import { useSnackbarStore } from "@/store/useSnackbarStore";
import SnackbarType from "@/store/interfaces/snackbar-type";
import { useI18n } from "vue-i18n";
import { getErrorByTypeOrDefault } from "@/utils/error-utils";

export function useTerminalBookingOverviewLogic() {
  const authStore = useAuthStore();
  const terminalStore = useTerminalStore();
  const forwarderStore = useForwarderStore();
  const snackBarStore = useSnackbarStore();

  const size = ref(50);
  const page = ref(0);
  const loading = ref(false);

  const { t } = useI18n();
  const terminalName = computed(() => terminalStore.terminal?.name);

  const bookings = ref<TerminalOperatorBookingTO[] | null>([]);

  const statusPriority: {
    [key in TerminalOperatorBookingTOStateEnum]: number;
  } = {
    [TerminalOperatorBookingTOStateEnum.TerminalReached]: 1,
    [TerminalOperatorBookingTOStateEnum.Active]: 2,
    [TerminalOperatorBookingTOStateEnum.Completed]: 3,
    [TerminalOperatorBookingTOStateEnum.RegistrationBlocked]: 4,
    [TerminalOperatorBookingTOStateEnum.RegistrationRestricted]: 5,
    [TerminalOperatorBookingTOStateEnum.Registered]: 6,
    [TerminalOperatorBookingTOStateEnum.Transhipment]: 7,
    [TerminalOperatorBookingTOStateEnum.Cancelled]: 8,
    [TerminalOperatorBookingTOStateEnum.CounterRequired]: 9,
  };

  function sortDateRaw(
    a: TerminalOperatorBookingTO,
    b: TerminalOperatorBookingTO,
  ): number {
    const dateA = new Date(a.timeSlot?.slotBegin || 0);
    const dateB = new Date(b.timeSlot?.slotBegin || 0);
    return compareAsc(dateA, dateB);
  }

  function sortStatusRaw(
    a: TerminalOperatorBookingTO,
    b: TerminalOperatorBookingTO,
  ): number {
    const statusA =
      statusPriority[a.state as TerminalOperatorBookingTOStateEnum] || 99;
    const statusB =
      statusPriority[b.state as TerminalOperatorBookingTOStateEnum] || 99;
    return statusA - statusB;
  }

  const sortedBookings = computed(() => {
    if (!bookings.value) {
      return [];
    }
    return [...bookings.value].sort((a, b) => {
      const statusComparison = sortStatusRaw(a, b);
      if (statusComparison !== 0) {
        return statusComparison;
      }
      return sortDateRaw(a, b);
    });
  });

  function formatTime(dateTime: string | undefined): string {
    return dateTime ? format(new Date(dateTime), "HH:mm") : "";
  }

  function formatDate(date: string | undefined): string {
    return date ? format(new Date(date), "dd.MM.yyyy") : "";
  }

  function getLedColor(state: TerminalOperatorBookingTOStateEnum): string {
    switch (state) {
      case TerminalOperatorBookingTOStateEnum.Completed:
        return "bg-green-accent-3";
      case TerminalOperatorBookingTOStateEnum.TerminalReached:
        return "bg-yellow-accent-2";
      case TerminalOperatorBookingTOStateEnum.Active:
      default:
        return "bg-grey-darken-1";
    }
  }

  async function fetchBookings(): Promise<TerminalOperatorBookingTO[]> {
    try {
      const userId = authStore.authUser?.userId;
      const terminalId = terminalStore.terminal?.id;

      if (!userId || !terminalId) {
        bookings.value = [];
        return [];
      }

      const { data } =
        await terminalOperatorTruckerClient.listTerminalOperatorBookings(
          userId,
          terminalId,
          page.value,
          size.value,
        );

      if (data && data.length > 0) {
        const newBookings = data.filter(
          newBooking =>
            !bookings.value?.some(
              existingBooking => existingBooking.id === newBooking.id,
            ),
        );

        if (newBookings.length > 0) {
          bookings.value = [...(bookings.value || []), ...newBookings];
          page.value++;
        }
      }

      return data ?? [];
    } catch (_error) {
      bookings.value = [];
      return [];
    }
  }

  async function rerouteToParking(booking: TerminalOperatorBookingTO) {
    const userId = authStore.authUser?.userId;
    if (userId && booking.id) {
      try {
        await fetchBookings();
        await terminalOperatorTruckerClient.rerouteTruckerFromBooking(
          userId,
          booking.id,
        );
        snackBarStore.showSnackbar({
          text: t("rerouteSuccessful"),
          snackbarType: SnackbarType.SUCCESS,
        });
      } catch (error) {
        const errorText = t(getErrorByTypeOrDefault(error, "rerouteFailed"));
        snackBarStore.showSnackbar({
          text: errorText,
          snackbarType: SnackbarType.ERROR,
        });
      }
    }
  }

  const handleLoadMore = async (
    done: (status: "loading" | "error" | "empty" | "ok") => void,
  ) => {
    if (loading.value) return;

    loading.value = true;

    done("loading");

    try {
      const loadedBookings = await fetchBookings();

      if (loadedBookings.length === 0) {
        done("empty");
      } else {
        done("ok");
      }
    } catch (_) {
      done("error");
    } finally {
      loading.value = false;
    }
  };

  watch(
    () => [terminalStore.terminal, forwarderStore.forwarder],
    async ([newTerminal, newForwarder], [oldTerminal, oldForwarder]) => {
      if (
        newTerminal?.id !== oldTerminal?.id ||
        newForwarder?.id !== oldForwarder?.id
      ) {
        page.value = 0;
        size.value = 50;
        await fetchBookings();
      }
    },
  );

  onMounted(async () => {
    await fetchBookings();
  });

  return {
    bookings,
    sortedBookings,
    formatTime,
    formatDate,
    getLedColor,
    sortDateRaw,
    sortStatusRaw,
    rerouteToParking,
    handleLoadMore,
    terminalName,
  };
}
