<template>
  <TheDialog
    :dialog="dialog"
    max-width="600px"
    :title="t('assign_license')"
    :submit-text="t('assign')"
    :cancel-text="t('cancel')"
  >
    <template #text>
      {{
        t("assign_license_description", {
          licenseKey: license.value?.licenseKey,
        })
      }}
      <div class="mt-5">
        <TheAutocomplete
          v-model="trucker"
          :items="truckerItems"
          :label="t('search')"
          :is-loading="truckersLoading"
          prepend-inner-icon="mdi-magnify"
          @on-search="handleSearch"
          @load-more="handleLoadMore"
        />
      </div>
    </template>
  </TheDialog>
</template>
<script setup lang="ts">
import TheDialog from "@/components/dialogs/TheDialog.vue";
import { UseDialogReturn } from "@/composables/useDialog";
import { useI18n } from "vue-i18n";
import { License } from "@/models/license-management";
import { computed, ref } from "vue";
import TheAutocomplete from "@/components/TheAutocomplete.vue";
import { TruckerForForwarderTO } from "@/services/client/generated";

interface Props {
  dialog: UseDialogReturn<License>;
  truckers?: TruckerForForwarderTO[] | null;
  truckersLoading?: boolean;
}

const emit = defineEmits(["onSearch", "onLoadMoreTruckers"]);
const props = defineProps<Props>();
const { t } = useI18n();

const trucker = ref<TruckerForForwarderTO | undefined>(undefined);

const truckerItems = computed(() => {
  return props.truckers?.map(trucker => {
    return {
      title: `${trucker.firstName} ${trucker.lastName}`,
      value: trucker,
    };
  });
});

function handleSearch(value: string) {
  emit("onSearch", value);
}

function handleLoadMore(done: unknown) {
  emit("onLoadMoreTruckers", done);
}

const license = computed(() => props.dialog.param);
</script>
