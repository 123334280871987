<template>
  <div>
    <VirtualTable
      :items="licenses"
      :headers="headers"
      :searchable="true"
      :search-text="$t('license_management_search')"
      :filterable="true"
      :pages-loaded="page"
      :search-filter-version="2"
      :min-width="1300"
      scrollable
      @load-more="handleLoadMore"
      @on-filter="handleFilter"
      @on-search="handleSearch"
    >
      <template #filter-custom-btn>
        <div class="text-end">
          <TheButton
            class="ml-auto mr-2"
            :title="i18n.t('claim_license')"
            size="default"
            :fluid="false"
            :block="false"
            variant="tonal"
            color="default"
            :elevation="0"
            @click="handleClaimLicense"
          />
          <TheButton
            class="ml-auto"
            :title="i18n.t('purchase_license')"
            size="default"
            :fluid="false"
            :block="false"
            :elevation="0"
            @click="handlePurchaseLicense"
          />
        </div>
      </template>

      <template #item.status="{ value }">
        <div class="text-start">
          <VirtualTableStatusChip
            :color="getChipColorByStatus(value.status)"
            :value="i18n.t(getLicenseChipStatusValue(value.status))"
          />
        </div>
      </template>

      <template #item.licenseKey="{ value }">
        <div class="text-start">
          {{ value.licenseKey }}
          <v-icon
            v-if="!value.hasRenewal"
            color="neutral-900"
            icon="mdi-close-circle"
          />
        </div>
      </template>

      <template #item.truckerName="{ value }">
        <div class="text-start">
          {{ value.truckerName }}
        </div>
      </template>

      <template #item.forwarder="{ value }">
        <div class="text-start">
          {{ value.forwarder }}
        </div>
      </template>

      <template #item.expirationDate="{ value }">
        <div class="text-start">
          {{ formatDate(value.expirationDate, "MM/dd/yyyy") }}
          <v-icon
            v-if="
              !value.hasRenewal &&
              getDateStatus(value.expirationDate) === 'expiring'
            "
            color="error"
            icon="mdi-alert"
          />
        </div>
      </template>

      <template #item.actions="{ value }">
        <LicenseManagementTableActions
          :actions="value.possibleAction"
          @onAssign="handleAssignLicense(value)"
          @onStopRenewal="handleStopRenewal(value)"
        />
      </template>
    </VirtualTable>
  </div>
</template>

<script setup lang="ts">
import VirtualTable from "@/components/virtual-table/VirtualTable.vue";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import TheButton from "@/components/TheButton.vue";
import VirtualTableStatusChip from "@/components/virtual-table/VirtualTableStatusChip.vue";
import {
  getChipColorByStatus,
  getLicenseChipStatusValue,
} from "@/utils/license-management/license-management-table-utils";
import { formatDate, getDateStatus } from "@/utils/date-utils";
import LicenseManagementTableActions from "@/components/license-management/LicenseManagementTableActions.vue";
import { License } from "@/models/license-management";
import { VirtualTableLoadMoreStatus } from "@/models/virtual-table";

interface Props {
  licenses: License[];
  page: number;
}

defineProps<Props>();
const emit = defineEmits([
  "onFilter",
  "onSearch",
  "onClaimLicense",
  "onPurchaseLicense",
  "onLoadMore",
  "onLicenseAssign",
  "onStopRenewal",
]);

const i18n = useI18n();

const headers = computed(
  () =>
    [
      {
        title: i18n.t("status"),
        value: "status",
        key: "status",
        align: "start",
      },
      {
        title: i18n.t("license_key"),
        value: "licenseKey",
        key: "licenseKey",
        align: "start",
        width: "20em",
      },
      {
        title: i18n.t("assigned_driver"),
        value: "truckerName",
        key: "truckerName",
        align: "start",
      },
      {
        title: i18n.t("license_manager"),
        value: "forwarder",
        key: "forwarder",
        align: "start",
      },
      {
        title: i18n.t("expiration_date"),
        value: "expirationDate",
        key: "expirationDate",
        align: "start",
      },
      { title: "Actions", value: "actions", align: "end" },
    ] as const,
);

function handleLoadMore(done: (status: VirtualTableLoadMoreStatus) => void) {
  emit("onLoadMore", done);
}

function handleSearch(value: string) {
  emit("onSearch", value);
}

function handleFilter() {
  emit("onFilter");
}

function handleClaimLicense() {
  emit("onClaimLicense");
}

function handlePurchaseLicense() {
  emit("onPurchaseLicense");
}

function handleAssignLicense(value: License) {
  emit("onLicenseAssign", value);
}

function handleStopRenewal(value: License) {
  emit("onStopRenewal", value);
}
</script>
