import SnackbarType from "@/store/interfaces/snackbar-type";
import { useSnackbarStore } from "@/store/useSnackbarStore";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

export interface TruckingCompany {
  id: string;
  companyName: string;
  companyEmail: string;
  taxId: string;
  createdAt: string;
  street: string;
  city: string;
  zipCode: string;
  country: string;
  tmsSystem: string;
}

export interface TruckingCompanyCreation {
  companyName: string;
  companyEmail: string;
  companyTaxId: string;
  companyStreetNo: string;
  companyCity: string;
  companyZipCode: string;
  companyCountry: string;
}

export function useTruckingCompaniesLogic() {
  const snackBarStore = useSnackbarStore();
  const { t } = useI18n();

  const truckingCompanies = ref<TruckingCompany[]>([
    {
      id: "1",
      companyName: "CONROO Trucking",
      companyEmail: "trucking@conroo.com",
      taxId: "123456789012",
      createdAt: "2025-01-30T08:30:00Z",
      street: "Zolhof 7",
      city: "Nürnberg",
      zipCode: "90232",
      country: "Germany",
      tmsSystem: "CONROO TMS",
    },
    {
      id: "2",
      companyName: "Spedition Schmid",
      companyEmail: "info@schmid.com",
      taxId: "123456789013",
      createdAt: "2025-01-29T08:30:00Z",
      street: "Waldweg 23",
      city: "Regensburg",
      zipCode: "90231",
      country: "Germany",
      tmsSystem: "DriveMyBox",
    },
    {
      id: "3",
      companyName: "MT Transporte",
      companyEmail: "info@mt-transporte.com",
      taxId: "123456789018",
      createdAt: "2025-01-30T08:30:00Z",
      street: "Keine Ahnung 12",
      city: "MT-Stadt",
      zipCode: "132456",
      country: "Germany",
      tmsSystem: "",
    },
  ]);

  async function fetchTruckingCompanies() {
    return truckingCompanies.value.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );
  }

  async function addTruckingCompany(newCompany: TruckingCompanyCreation) {
    try {
      truckingCompanies.value.push({
        id: Math.random().toString(36).substr(2, 9),
        companyName: newCompany.companyName,
        companyEmail: newCompany.companyEmail,
        taxId: newCompany.companyTaxId,
        createdAt: new Date().toISOString(),
        street: newCompany.companyStreetNo,
        city: newCompany.companyCity,
        zipCode: newCompany.companyZipCode,
        country: newCompany.companyCountry,
        tmsSystem: "",
      });
      snackBarStore.showSnackbar({
        text: t("truckingCompany.company_created_successfully"),
        snackbarType: SnackbarType.SUCCESS,
      });
    } catch (e) {
      snackBarStore.showSnackbar({
        text: t(`Error: ${e}`),
        snackbarType: SnackbarType.ERROR,
      });
    }
  }

  return { truckingCompanies, fetchTruckingCompanies, addTruckingCompany };
}
